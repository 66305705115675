.footer {
  padding-top: 50px;
  background-color: #1e1e1e;
}

.footer .list li {
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.footer .list li a {
  text-decoration: none;
  color: white;
}

.footer .list {
  list-style: none;
}

.footer .social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  list-style: none;
  padding-left: 0px;
}

.footer .social li a {
  color: #c71661;
  text-align: center;
  background-color: #fff;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  display: inline-block;
  font-size: 16px;
  transition: all 0.3s ease 0s;
}

.footer .copyright {
  text-align: center;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.88px;
  text-transform: uppercase;
}

.footer .mini-content ul {
  padding-left: 0px;
}

.instagram:hover {
  background-color: #d62976 !important;
  transition-duration: .3s;
}

.twitter:hover {
  background-color: #00acee !important;
  transition-duration: .3s;
}

.facebook:hover {
  background-color: #0072b1 !important;
  transition-duration: .3s;
}

.instagram:hover .socialSvg,
.twitter:hover .socialSvg,
.facebook:hover .socialSvg {
  color: #ffffff;
}

.googleplay:hover {
  background-color: #ffffff !important;
  transition-duration: .3s;
}

.applestore:hover {
  background-color: #ffffff !important;
  transition-duration: .3s;
}

.applestore:hover .socialSvg,
.googleplay:hover .socialSvg {
  color: #000000;
}

.social li a:active {
  transform: scale(0.9);
  transition-duration: .3s;
}

.social li a:hover .socialSvg {
  animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* ******************************************************* MEDIA-QUERY ********************************************************* */

@media only screen and (max-width: 767px) {
  .mini-content {
    text-align: center;
  }
}

@media only screen and (max-width: 380px) {
  .footer .social {
    flex-wrap: wrap;
  }
}