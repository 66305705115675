/* ****************************************************** weeklybuzz-event ****************************************************** */


.weeklybuzz-event {
    padding: 50px 0px 100px;
}

.weeklybuzz-event-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    height: 100%;
}

.weeklybuzz-event-text {
    width: 50%;
}

.weeklybuzz-event-img {
    width: 35%;
    height: 100%;
}

.weeklybuzz-event-img img {
    width: 100%;
    object-fit: contain;
}


.weeklybuzz-event-text-heading h3 {
    color: white;
    text-transform: capitalize;
}

.weeklybuzz-event-text-paragraph h6 {
    color: #ffffff;
    line-height: 28px;
    letter-spacing: 0.5px;
    font-weight: 400;
}

.weeklybuzz-event-text-paragraph a {
    text-decoration: none;
    color: aqua;
    margin-top: 10px !important;
}



/* ****************************************************** weeklybuzz-ticket ****************************************************** */

.weeklybuzz-ticket {
    background-color: var(--background-color-gray);
}

.weeklybuzz-ticket-content {
    padding: 100px 0px;
}

.weeklybuzz-ticket-sub-title h6 {
    font-weight: 400;
    color: var(--text-color-secondary);
    line-height: 28px;
    letter-spacing: 1px;
}

.weeklybuzz-ticket-sub-title {
    width: 55%;
    margin: 0 auto;
}

.weeklybuzz-ticket-cards {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 60px;
}

.weeklybuzz-ticket-card {
    width: 30%;
    background-color: var(--background-color-secondary);
    border-radius: 20px;
    padding: 30px;
    text-align: center;
    box-shadow: var(--text-shadow) 0px 8px 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.weeklybuzz-ticket-card-price {
    background-color: #8261ee;
    border-radius: 20px;
    padding: 20px;
    color: white;
    margin-top: 20px;
}

.weeklybuzz-ticket-card-title h6 {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto;
}

.weeklybuzz-ticket-card-details h6 {
    font-weight: 400;
    color: var(--text-color-secondary);
    line-height: 28px;
    letter-spacing: 1px;
    margin: 10px 0px;
    font-size: 14px;
}

.weeklybuzz-ticket-card-btn button {
    padding: 1.3em 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #fff;
    background-color: #c71661;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
}

.weeklybuzz-ticket-card-btn button.bg-green {
    background-color: #2e8b57;
}

.weeklybuzz-ticket-card-btn button:hover {
    background-color: #c71661;
    box-shadow: 0px 15px 20px #c7166067;
    color: #fff;
    transform: translateY(-7px);
}

.weeklybuzz-ticket-card-btn button.bg-green:hover {
    background-color: #2e8b57;
    box-shadow: 0px 15px 20px #2e8b565b;
    color: #fff;
    transform: translateY(-7px);
}

.weeklybuzz-ticket-card-btn button:active {
    transform: translateY(-1px);
}

.weeklybuzz-ticket-card-btn button:hover::before {
    animation: shine 1.5s ease-out infinite;
}

.weeklybuzz-ticket-card-btn button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(120deg,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0) 70%);
    top: 0;
    left: -100px;
    opacity: 0.6;
}

@keyframes shine {
    0% {
        left: -100px;
    }

    60% {
        left: 100%;
    }

    to {
        left: 100%;
    }
}


/* ******************************************************* MEDIA-QUERY ********************************************************* */




@media only screen and (max-width:991px) {

    .weeklybuzz-ticket-sub-title {
        width: 100%;
        margin: 0 auto;
    }


}

@media only screen and (max-width:767px) {

    .weeklybuzz-ticket-cards {
        flex-wrap: wrap;
    }

    .weeklybuzz-ticket-card {
        width: 45%;
    }

}

@media only screen and (max-width:710px) {

    .weeklybuzz-event {
        padding-top: 0px;
    }

    .weeklybuzz-event-content {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        gap: 30px;
    }

    .weeklybuzz-event-img {
        width: 60%;
        margin: 0 auto;
    }

    .weeklybuzz-event-text {
        width: 100%;
    }

}


@media only screen and (max-width:680px) {}

@media only screen and (max-width:576px) {

    .weeklybuzz-ticket-card {
        width: 70%;
    }

}

@media only screen and (max-width:380px) {

    .weeklybuzz-ticket-card {
        width: 90%;
    }

}