.contact-us {
  background-color: var(--background-color);
}

.contact-us-content {
  padding: 100px 0px;
}

.contact-us-content .container {
  position: relative;
  padding: 50px;
  margin-top: -700px;
  z-index: 1;
  border-radius: 30px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    background-color: var(--background-color-secondary);
}

.contact-us-title h2 {
  text-transform: capitalize;
}

.contact-us-sub-title h6 {
  font-weight: 600;
  color: var(--text-color-secondary);
  line-height: 28px;
  letter-spacing: 1px;
}

.contact-us-detail {
  width: 50%;
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid var(--input-border-color);
}

.contact-us-contactdetail {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.contact-us-detail-form {
  width: 100%;
}

.contact-us-mail h5,
.contact-us-number h5 {
  font-weight: 400;
  text-transform: capitalize;
}

.contact-us-mail h6 a,
.contact-us-number h6 a {
  font-weight: 400;
  text-decoration: none;
  color: #6F8BA4;
}

.contact-us-detail-form-field {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.contact-us-detail-form-field .form-field {
  width: 48%;
}

.form-field-area .error {
  color: red;
  margin-left: 20px;
}

.contact-us-detail-form-field .name-input,
.contact-us-detail-form-field .email-input {
  width: 100%;
}

.contact-us-detail-form input,
.contact-us-detail-form textarea {
  padding: 12px 20px;
  border-radius: 25px;
  font-size: 14px;
  border: 2px solid var(--input-border-color);
  background-color: var(--input-background-color);
  color: var(--text-color-primary) !important;
}

.contact-us-detail-form input:focus,
.contact-us-detail-form textarea:focus {
  border: 2px solid #eecee9;
  box-shadow: 0 0 0 0.25rem #eecee9;
  background-color: var(--input-background-color);
}

.contact-us-detail-form input::placeholder, .contact-us-detail-form textarea::placeholder{
  color: var(--text-color-primary);
}

/* .contact-us-detail-form-btn button {
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 13px;
  border-radius: 20px;
  padding: 12px 20px;
  background-color: #c71661;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.25px;
} */

.contact-us-detail-form-btn {
  width: 300px;
  margin: 0 auto;
}

.contact-us-detail-form-btn button {
  width: 100%;
  padding: 1.3em 3em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #ffffff;
  background-color: #c71661;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin-top: 20px;
  overflow: hidden;
}

.contact-us-detail-form-btn button:hover {
  background-color: #c71661;
  box-shadow: 0px 15px 20px #c7166067;
  color: #fff;
  transform: translateY(-7px);
}

.contact-us-detail-form-btn button:active {
  transform: translateY(-1px);
}

.contact-us-detail-form-btn button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.contact-us-detail-form-btn button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%);
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

/* ******************************************************* MEDIA-QUERY ********************************************************* */

@media only screen and (max-width: 991px) {
  .contact-us-contactdetail {
    width: 100%;
  }

  .contact-us-detail-form {
    width: 100%;
  }

  .contact-us-detail-form-field .name-input,
  .contact-us-detail-form-field .email-input {
    width: 100%;
  }

  .contact-us-content .container{
    padding: 30px ;
  }
}

@media only screen and (max-width: 690px) {
  .contact-us-detail-form-field .form-field {
    width: 100%;
  }

  .contact-us-contactdetail{
    flex-wrap: wrap;
  }
  .contact-us-contactdetail .contact-us-detail{
    width: 100%;
  }
}