.alphaone-radio {
    background: url('../../Images/work-process-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alphaone-radio-content {
    padding: 100px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
}

.alphaone-radio-text {
    width: 50%;
}

.alphaone-radio-img {
    width: 35%;
}

.alphaone-radio-text h1 {
    font-size: 50px;
    font-weight: bold;
    font-family: Segoe Script;
    color: white;
    text-shadow: -4px 4px 4px #C71661;
}

.alphaone-radio-text h6 {
    color: white;
    font-weight: 400;
    margin-top: 40px;
}

.alphaone-radio-img img {
    width: 100%;
    object-fit: contain;
}

/* ******************************************************* STATION PODCAST ********************************************************* */


.station-podcast {
    padding: 50px 0px 100px;
    background-color: var(--background-color-gray);
}

.station-podcast-title h2 {
    color: #C71661;
    font-size: 50px;
    font-weight: bold;
    font-family: Segoe Script;
    text-shadow: -4px 4px 4px var(--text-shadow)
}

.station-podcast-subtitle h6 {
    color: var(--text-color-secondary);
    margin-bottom: 30px;
}

.station-podcast-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 50px;
}

.station-podcast-card {
    width: 28%;
    background-color: var(--background-color-secondary);
    border-radius: 10px;
    box-shadow: 4px 4px 10px gray;
    padding: 20px;
    box-shadow: var(--text-shadow) 0px 8px 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.station-podcast-card-img {
    width: 100%;
    height: 200px;
}

.station-podcast-card-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.station-podcast-card-text h5 {
    text-transform: capitalize;
    color: var(--text-color-primary);
    font-size: 25px;
}

.station-podcast-card-text h6 {
    font-weight: 400;
    color: var(--text-color-secondary);
    letter-spacing: 0.26px;
    line-height: 26px;
    margin-top: 10px;
}

.station-podcast-card-btn button {
    padding: 1.3em 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    color: #fff;
    background-color: #c71661;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    margin-top: 20px;
    position: relative;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.station-podcast-card-btn button.bg-green {
    background-color: #2e8b57;
}

.station-podcast-card-btn button:hover {
    background-color: #c71661;
    box-shadow: 0px 15px 20px #c7166067;
    color: #fff;
    transform: translateY(-7px);
}

.station-podcast-card-btn button.bg-green:hover {
    background-color: #2e8b57;
    box-shadow: 0px 15px 20px #2e8b565b;
    color: #fff;
    transform: translateY(-7px);
}

.station-podcast-card-btn button:active {
    transform: translateY(-1px);
}

.station-podcast-card-btn button:hover::before {
    animation: shine 1.5s ease-out infinite;
}

.station-podcast-card-btn button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(120deg,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0) 70%);
    top: 0;
    left: -100px;
    opacity: 0.6;
}

@keyframes shine {
    0% {
        left: -100px;
    }

    60% {
        left: 100%;
    }

    to {
        left: 100%;
    }
}

.custom-audio-player {
    background-color: red;
}

/* ******************************************************* MEDIA QUERY ********************************************************* */

@media only screen and (max-width:1024px) {

    .station-podcast-card {
        width: 30%;
    }

}


@media only screen and (max-width:991px) {

    .alphaone-radio-content {
        flex-wrap: wrap;
        justify-content: center;
    }

    .alphaone-radio-text,
    .alphaone-radio-img {
        width: 80%;
    }

    .alphaone-radio-img {
        margin-top: 30px;
    }

    .station-podcast-card {
        width: 40%;
    }

}

@media only screen and (max-width:767px) {

    .station-podcast-card {
        width: 45%;
    }

}


@media only screen and (max-width:680px) {

    .alphaone-radio-text,
    .alphaone-radio-img {
        width: 90%;
    }

    .station-podcast-card {
        width: 70%;
    }
}

@media only screen and (max-width:600px) {}

@media only screen and (max-width:480px) {

    .alphaone-radio-text,
    .alphaone-radio-img {
        width: 100%;
    }

    .alphaone-radio-img img {
        width: 100%;
        object-fit: contain;
    }

    .station-podcast-card {
        width: 80%;
    }

}

@media only screen and (max-width:380px) {

    .station-podcast-card {
        width: 100%;
    }
}