.Navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  height: 100px !important;
}

.Navbar .container {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 6px 18px, rgba(17, 17, 26, 0.1) 0px 8px 18px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(9px);
  border-radius: 40px;
  padding: 5px 30px;
}

.Navbar .navbar-brand {
  width: 50px;
}

.Navbar .navbar-brand img {
  width: 100%;
  object-fit: contain;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: #d32499 !important;
}

.activate {
  color: black !important;
  font-weight: 600;
}

.nav-item a {
  letter-spacing: 1px;
  color: black;
}

.collapse {
  transition: height 0.4s ease-in-out;
}

.navbar-toggler {
  outline: none;
  border: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}



@media only screen and (max-width:991px) {

  .navbar-brand img,
  button.navbar-toggler {
    margin: 0px 20px !important;
  }

  .navbar .navbar-nav {
    margin-right: 0px !important;
  }

  .navbar .navbar-nav .nav-item {
    margin-right: 0px !important;
    margin-left: 0px !important;
    padding: 5px 20px;
  }

  .navbar .navbar-nav .nav-item .dropdown-menu {
    border: none;
    width: 100%;
    background-color: #f2f2fe;
  }

  .donate-btn {
    margin: 5px 20px;
  }

  .collapse:not(.show) {
    display: block;
    height: 0;
    overflow: hidden;
  }


  .collapse.show {
    height: auto;
  }

}


.donate-btn {
  position: relative;
  transition: all 0.3s ease-in-out;
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: #d32499;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  text-decoration: none;
}

.more-btn .donate-btn {
  background-color: #C61661;
}

.donate-btn:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.donate-btn:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.donate-btn::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%);
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}