.artist-detail {
    padding: 180px 0px 100px;
}

.artist-detail-content {
    display: flex;
    gap: 50px;
    justify-content: flex-start;
}

.artist-detail-img {
    width: 400px;
    height: 400px;
    border: 10px solid #C71661;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;

}

.artist-detail-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.artist-detail-text {
    width: 60%;
}

.artist-detail-text-title h2 {
    color: #C71661;
    font-size: 50px;
    font-weight: bold;
    font-family: Segoe Script;
    text-shadow: -4px 4px 4px var(--text-shadow);
}

.artist-detail-text-part h6 {

    font-weight: 400;
    font-size: 16px;
    color: var(--text-color-secondary);
    line-height: 28px;
    letter-spacing: 1px;
}

.artist-detail-icons {
    background-color: #d32499;
    border-radius: 40px;
    box-shadow: 4px 4px 5px black;
    width: 60%;
    margin-top: 30px;
}

.artist-detail-icons .social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    list-style: none;
    margin-bottom: 0px;
}

.artist-detail-icons .social li a {
    color: #C71661;
    text-align: center;
    background-color: #fff;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    display: inline-block;
    font-size: 16px;
    transition: all 0.3s ease 0s;
}

.artist-detail .live-section-text-content-icons .social {
    padding-left: 0px;
}

.artist-detail .live-section-text-content-icons .social li a {
    background-color: #C71661;
    color: #fff;
}


@media only screen and (max-width:1152px) {
    .artist-detail-icons {
        width: 80%;
    }
}

@media only screen and (max-width:991px) {
    .artist-detail-content {
        flex-wrap: wrap;
    }

    .artist-detail-img {
        margin: 0 auto;
    }

    .artist-detail-text {
        width: 100%;
        text-align: center;
    }

    .artist-detail-icons {
        width: 60%;
        margin: 0 auto;
        margin-top: 30px;
    }

    .artist-detail-icons .social {
        justify-content: center;
        padding-left: 0px;
    }
}

@media only screen and (max-width:680px) {

    .artist-detail-img {
        width: 300px;
        height: 300px;
    }

    .artist-detail-img::before {
        width: 100%;
        height: 300px;
    }

    .artist-detail-icons {
        width: 80%;
        margin: 0 auto;
        margin-top: 30px;
    }

}

@media only screen and (max-width:480px) {

    .artist-detail-img {
        width: 280px;
        height: 280px;
    }

    .artist-detail-img::before {
        width: 100%;
        height: 280px;
    }

    .artist-detail-icons {
        width: 100%;
        margin: 0 auto;
        margin-top: 30px;
    }

    .artist-detail-icons .social {
        flex-wrap: wrap;
    }

}