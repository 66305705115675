.donate {
    background-color: var(--background-color);
    padding-bottom: 100px;
}


.donate .welcome-area {
    min-height: 750px !important;
}

.donate-content .container {
    position: relative;
    padding: 50px;
    margin-top: -600px;
    z-index: 1;
    border-radius: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    background-color: var(--background-color-secondary);
}

.donate-account-detail-content {
    display: flex;
    gap: 30px;
}

.donate-title h2 {
    color: #C71661;
    font-size: 50px;
    font-weight: bold;
    font-family: Segoe Script;
    text-transform: capitalize;
    text-shadow: -4px 4px 4px var(--text-shadow);
}

.donate-sub-title h6 {
    font-weight: 600;
    color: var(--text-color-secondary);
    line-height: 28px;
    letter-spacing: 1px
}

.donate-account-detail {
    width: 100%;
    border-radius: 30px;
    padding: 30px;
}

.donate-account-detail-title h4 {
    color: #d32499;
}

.donate-detail {
    flex-grow: 1;
    border: 1px solid;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid var(--input-border-color);

}

.donate-detail-form {
    width: 100%;
}

.donate-detail-form-title h6 {
    font-weight: 400;
    color: var(--text-color-secondary);
}

.donate-account-number h5,
.donate-account-name h5,
.donate-bank-name h5 {
    font-weight: 400;
    text-transform: capitalize;
}

.donate-account-number h6,
.donate-account-name h6,
.donate-bank-name h6 {
    font-weight: 400;
    color: #6F8BA4;
    letter-spacing: 0.6px;
    line-height: 26px;
    font-size: 14px;
}

.donate-detail-form-field {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.donate-detail-form-field .form-field {
    width: 48%;
}

.donate-detail-form .name-input,
.donate-detail-form .email-input {
    width: 100%;
}

.donate-detail-form input {
    padding: 12px 20px;
    border-radius: 25px;
    font-size: 14px;
    border: 2px solid var(--input-border-color);
    background-color: var(--input-background-color);
    color: var(--text-color-primary) !important;
}

.donate-detail-form input:focus {
    border: 2px solid #eecee9;
    box-shadow: 0 0 0 0.25rem #eecee9;
    background-color: var(--input-background-color);
}

.donate-detail-form input::placeholder,
.donate-detail-form textarea::placeholder {
    color: var(--text-color-primary);
}


/* .donate-detail-form-btn button {
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 13px;
    border-radius: 20px;
    padding: 12px 20px;
    background-color: #C71661;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.25px;
} */

.donate-detail-form-btn {
    width: 300px;
    margin: 0 auto;
}

.donate-detail-form-btn button {
    width: 100%;
    padding: 1.3em 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #ffffff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    margin-top: 20px;
    background-color: #c71661;
    overflow: hidden;
}

.donate-detail-form-btn button:hover {
    background-color: #c71661;
    box-shadow: 0px 15px 20px #c7166067;
    color: #fff;
    transform: translateY(-7px);
}

.donate-detail-form-btn button:active {
    transform: translateY(-1px);
}

.donate-detail-form-btn button:hover::before {
    animation: shine 1.5s ease-out infinite;
}

.donate-detail-form-btn button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(120deg,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0) 70%);
    top: 0;
    left: -100px;
    opacity: 0.6;
}

@keyframes shine {
    0% {
        left: -100px;
    }

    60% {
        left: 100%;
    }

    to {
        left: 100%;
    }
}



/* ******************************************************* MEDIA-QUERY ********************************************************* */


@media only screen and (max-width:991px) {


    .donate-account-detail {
        width: 100%;
    }

    .donate-detail-form {
        width: 100%;
        margin-top: 50px;
    }

    .donate-detail-form .name-input,
    .donate-detail-form .email-input {
        width: 100%;
    }

    .donate-content .container {
        padding: 30px;
    }

    .donate-account-detail {
        padding: 0px;
        padding-top: 30px;
    }

}

@media only screen and (max-width:850px) {

    .donate-account-detail-content {
        flex-wrap: wrap;
        gap: 0px;
    }

    .donate-detail {
        width: 100%;
    }

}

@media only screen and (max-width:740px) {

    .donate-detail-form .form-field {
        width: 100%;
    }


}