.live .welcome-area-title {
    color: white;
    text-align: center;
}

.live .hero-section {
    position: relative;
}

.live .hero-section .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
}

.bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(0deg, rgb(199 22 97 / 40%), rgb(199 22 97 / 40%)) !important; */
    background: rgb(0 0 0 / 50%);
    z-index: 0;
}

.live .hero-section .bg-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.live .hero-section .welcome-area {
    background-image: none !important;

    align-items: flex-end !important;
    padding-bottom: 30px !important;
    position: relative;
    z-index: 1;
}

.live .live-section-content {
    display: flex;
    gap: 30px;
    margin-top: 30px;
    border-radius: 20px;
    background-color: rgba(255, 0, 106, 0.5);
    backdrop-filter: blur(8px) !important;
    position: relative;
    padding: 10px;
}

.live-section-card {
    min-width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 20px;
}

.live-section-card-img {
    height: 100%;
    width: 100%;
}

.live-section-card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.live-section-card-title {
    text-align: center;
    margin: 15px 0px;
}

/* .live-section-card-btn button {
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 13px;
    border-radius: 20px;
    padding: 12px 20px;
    background-color: #C71661;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.25px;
} */

.live-section-card-btn {
    margin: 0 auto;
    margin-top: 15px;
    width: 200px;
    padding: 15px 30px;
    position: relative;
    display: block;
    text-decoration: none;
    overflow: hidden;
    border: 0;
    cursor: pointer;
    border-radius: 2rem;
    /* box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5); */
}

.live-section-card-btn:hover .wave {
    top: -120px;
}

.main-button {
    position: relative;
    z-index: 1;
    color: white;
    font-size: 17px;
    letter-spacing: 1px;
    font-weight: 600;
}


.live-section-text-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.live-section-text-content-title h1 {
    color: #fff;
    font-weight: bold;
    /* font-family: Segoe Script; */
    /* text-shadow: -4px 4px 4px black; */
}

.live-section-text-content-paragraph h6 {
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    line-height: 28px;
    letter-spacing: 0.25px;
}

.live-section-text-content-icons {
    display: flex;
    align-items: end;
    justify-content: flex-start;
}

.live-section-text-content-audio {
    background: #fff;
    border-radius: 50%;
    width: 100px;
    height: 100px;

    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #c71661;
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.15);
}

.live-section-text-content-audio svg {
    font-size: 35px;
    color: #C71661;
}

.live-section-text-content-audio:hover {
    cursor: pointer;
}

.live-section-text-content-audio audio {
    width: 60%;
    box-shadow: -5px 5px black;
    border-radius: 30px;
    opacity: 1;
}

.live-section-text-content-icons .social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    list-style: none;
}

.live-section-text-content-icons .social li a {
    color: #C71661;
    text-align: center;
    background-color: #fff;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    transition: all 0.3s ease 0s;
}

.live-section-text-content-icons .social li a:hover {
    background-color: #C71661;
    color: #fff;
}

.live-section-content ul {
    padding-left: 0px !important;
}

.alt-img {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: black;
}

.alt-img img {
    object-fit: fill !important;
}

.bg-img .bg-alt-img {
    width: 100%;
    height: 100%;
}

.bg-img .alt-img img {
    width: 300px !important;
    height: 300px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
}

/* ******************************************************* RECENTLY-PLAYED ********************************************************* */

.recently-played {
    padding: 50px 0px;
}

.recently-played-title {
    text-align: center;
}

.recently-played-title h2 {
    color: #C71661;
    font-size: 50px;
    font-weight: bold;
    font-family: Segoe Script;
    text-shadow: -4px 4px 4px var(--text-shadow);
}

.recently-played-cards {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    height: 100%;
    padding: 0 50px;
}

.recently-played-card {
    max-width: 300px;
    width: 100%;
    min-height: 100%;
    box-shadow: rgba(17, 12, 46, 0.2) 0px 10px 20px 0px;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--background-color-secondary);
}

.recently-played-card:hover .recently-played-card-img img {
    transform: scale(1.1);
}

.recently-played-card:hover {
    /* cursor: pointer; */
}

.recently-played-card-img {
    height: 250px;
    overflow: hidden;
}

.recently-played-card-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
}


.recently-played-card-content {
    padding: 15px;
}

.recently-played-card-content h6 {
    margin-top: 10px;
    color: var(--text-color-secondary);
    font-weight: 400 !important;
}

/* ******************************************************* MEDIA-QUERY ********************************************************* */

@media only screen and (max-width: 1350px) {

    .recently-played-card-content h5 {
        font-size: 18px;
    }

}

@media only screen and (max-width:1230px) {

    .live-section-text-content-audio audio {
        width: 70%;
    }

    .recently-played-card-content h5 {
        font-size: 16px;
    }

}

@media only screen and (max-width:1080px) {
    .recently-played-cards {
        flex-wrap: wrap;
    }

    .recently-played-card {
        max-width: 310px;
    }

}

@media only screen and (max-width:1050px) {
    .live-section-text-content-title h1 {
        font-size: 30px !important;
    }

    .live-section-text-content-title h5 {
        font-size: 18px !important;
    }
}

@media only screen and (max-width:991px) {

    .live-section-text-content-audio audio {
        width: 90%;
    }

    .live-section-text-content-icons{
        justify-content: center;
    }

}

@media only screen and (max-width:850px) {

    .welcome-area {
        padding: 50px 0px;
    }

    .live-section-text-content-title h1 {
        font-size: 26px !important;
    }

    .live-section-text-content-title h5 {
        font-size: 14px !important;
    }

    .live-section-card {
        width: 150px;
        height: 150px;
    }

    .live .live-section-content {
        gap: 20px;
    }

    .live-section-text-content-audio {
        width: 80px;
        height: 80px;
        bottom: 20px;
    }

    .live-section-text-content-audio svg {
        font-size: 28px;
    }

    .live-section-text-content-icons .social {
        gap: 15px;
    }

}

@media only screen and (max-width:776px) {
    .recently-played-cards {
        padding: 0px 30px;
    }

    .recently-played-card {
        max-width: 250px;
    }

    .recently-played-card-img {
        height: 200px;
    }


}

@media only screen and (max-width:660px) {


    .live-section-card {
       display: none;
    }

    .live-section-text-content {
        width: 100%;
        padding-left: 10px;
    }

    .live-section-text-content-icons .social {
        margin-top: 25px;
    }

}

@media only screen and (max-width:600px) {
    .recently-played-card {
        max-width: 350px;
    }

    .recently-played-card-img {
        height: 250px;
    }
}

@media only screen and (max-width:550px) {


    .live-section-text-content-audio {
        /* right: -10px;
        bottom: -10px; */
    }

    .live .live-section-content {
        overflow: visible;
    }

    .live .social {
        padding-right: 60px;
    }


}

@media only screen and (max-width:576px) {

    .live .social {
        padding-left: 0px;
        flex-wrap: wrap;
    }

}