.player_container {
  width: 100%;
  padding: 1rem;
  color: rgb(218, 218, 218);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #c71661;
}

.modal-content {
  background-color: #c71661 !important;
}

.title {
  font-size: inherit;
  color: #fff;
}

.navigation {
  width: 100%;
}

.navigation_wrapper {
  min-width: 100%;
  background-color: #000;
  height: 5px;
  border-radius: 30px;
  cursor: pointer;
}

.seek_bar {
  width: 0;
  height: 100%;
  background-color: #fff;
  border-radius: 30px;
}

.controls {
  margin-top: 20px;
  font-size: inherit;
  display: flex;
  align-items: center;
}

.controls svg path {
  color: white;
}

.btn_action {
  font-size: 2rem;
  margin: 0 1rem;
  cursor: pointer;
}

.btn_action:hover {
  color: white;
}

.pp {
  font-size: 4rem;
}
