.menu {
    position: relative;
}

.menu ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #ffc7ec;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.menu li {
    color: black;
    display: block;
    transform-origin: -20px 50%;
}

.menu ul,
.menu li {
    list-style: none;
    margin: 0;
    padding: 10px;
}

.menu button {
    border: none;
    border-radius: 10px;
    /* padding: 8px 0px; */
    font-size: 16px;
    cursor: pointer;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    height: 40px;
    color: black;
}

.menu button path {
    fill: var(--background);
}

.menu-list {
    position: absolute;
    top: 40px;
    left: -20px;
    z-index: 99999;
    width: 200px;
    gap: 0px !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}

.menu-list a {
    text-decoration: none;
    color: black;
}

.menu-list li:hover {
    background-color:   white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}


.dropdown-arrow {
    width: 10px;
    height: 10px;
    display: inline-block;
    position: relative;
    bottom: -4px;
    left: -3px;
    transition: 0.4s ease;
    text-align: left;
    transform: rotate(45deg) !important;
}

.dropdown-arrow::before,
.dropdown-arrow::after {
    position: absolute;
    content: '';
    display: inline-block;
    width: 7px;
    height: 2px;
    background-color: black;
    transition: 0.4s ease;
    border-radius: 2px;
}

.arrow{
    transform: none;
}

.dropdown-arrow::after {
    position: absolute;
    transform: rotate(90deg) !important;
    top: -3px;
    left: 3px;
}

.dropdown-arrows.dropdown-arrow::before {
    transform: rotate(-90deg) !important;

}

.dropdown-arrows.dropdown-arrow::after {
    transform: rotate(180deg) !important;
}
