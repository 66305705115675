.daily-updates {
    padding: 150px 0px 100px 0px;
}

.daily-updates .blog-post-img img {
    object-fit: fill;
}

.daily-verse-title h2,
.daily-jokes-title h2,
.daily-dose-title h2 {
    color: #C71661;
    font-size: 50px;
    font-weight: bold;
    font-family: Segoe Script;
    text-shadow: -4px 4px 4px var(--text-shadow);
}

.daily-jokes,
.daily-dose {
    margin-top: 100px;
}

.daily-updates .blog-post-thumb {
    position: relative;
}

.daily-updates .blog-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 120%;
    transform: translateY(-30%);
    left: 0;
    transition: all 0.5s linear, ;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -o-transform: translateY(-30%);
}

.daily-updates .blog-post-img {
    height: 400px;
}

.daily-updates .blog-post-thumb:hover .blog-content {
    top: 30%;
    background-color: rgba(0, 0, 0, 0.493);
    backdrop-filter: blur(10px);
}

.daily-updates .blog-post-thumb:hover .blog-content.blog-loader {
    display: none !important;
}

.daily-updates .blog-content .main-button{
    position: relative;
    overflow: hidden;
}

.daily-updates .blog-content .main-button:hover {
    transform: scale(1.05);
    color: #ffffff;
    /* border-color: #fff9; */
}

.daily-updates .blog-content .main-button:hover::before {
    animation: shine 1.5s ease-out infinite;
}

.daily-updates .blog-content .main-button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(120deg,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0) 70%);
    top: 0;
    left: -100px;
    opacity: 0.6;
}

@keyframes shine {
    0% {
        left: -100px;
    }

    60% {
        left: 100%;
    }

    to {
        left: 100%;
    }
}