.about-us {
    background-color: var(--background-color);
    padding-bottom: 100px;
}

.about-us-content {
    padding: 50px 0px;
}

.about-us .container {
    position: relative;
    padding: 50px;
    margin-top: -600px;
    z-index: 1;
    border-radius: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    background-color: var(--background-color-secondary);

}

.about-us-title h2 {
    color: #C71661;
    font-size: 50px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: Segoe Script;
    text-shadow: -4px 4px 4px var(--text-shadow);
}

.about-us-detail {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.about-us-img {
    width: 35%;
    height: 250px;
}

.about-us-img img {
    width: 115%;
    height: 100%;
    object-fit: contain;
}

.about-us-who-we-are {
    width: 60%;
}

.about-us-who-we-are-title h3,
.about-us-our-mission-title h3,
.about-us-our-vision-title h3 {
    color: #C71661;
    text-transform: capitalize;
}

.about-us-who-we-are-detail h6,
.about-us-our-mission-detail h6,
.about-us-our-vision-detail h6 {
    line-height: 28px;
    font-weight: 400;
    margin: 20px 0px;
    color: var(--text-color-primary);
}

.about-us-our-vision,
.about-us-our-mission {
    width: 100%;
}






/* ******************************************************* MEDIA-QUERY ********************************************************* */


@media only screen and (max-width: 1300px){

  

}


@media only screen and (max-width:776px) {

    .about-us-img {
        width: 50%;
        margin: 0 auto;
    }

    .about-us-who-we-are {
        width: 100%;
    }

}

@media only screen and (max-width:380px) {
    .about-us-img {
        width: 70%;
    }

}