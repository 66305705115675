/* @import url("https://fonts.googleapis.com/css?family=Raleway:100,300,400,500,700,900"); */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --background-color: #ffffff;
  --background-color-secondary: #ffffff;
  --background-color-gray: #f2f2fe;
  --text-color-primary: #000000;
  --text-color-secondary: #777;
  --text-shadow: #777;
  --input-background-color: #ffffff;
  --input-border-color: #dee2e6;
}

[data-theme="dark"] {
  --background-color: #121212;
  --background-color-secondary: #1e1e1e;
  --text-color-primary: #ffffff;
  --text-color-secondary: #a5a5a5;
  --text-shadow: #000000;
  --input-background-color: #464646;
  --input-border-color: #686868;
  --background-color-gray: #121212;
}

body {
  font-family: "Inter", sans-serif !important;
  background-color: var(--background-color);
  color: var(--text-color-primary);
}


h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.color-green {
  color: #2e8b57 !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c71661;
}

.modal {
  z-index: 999999 !important;
  backdrop-filter: blur(5px);
}

.Toastify__progress-bar--success {
  background: #c71661 !important;
}

.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #c71661 !important;
}

/* .swiper-wrapper {
    justify-content: center;
} */

.model-icons {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.model-icons img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.share-modal .modal-content {
  background-color: #ffffff !important;
  padding: 20px;
}

.copy-url-field {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.copy-url-field .form-field {
  width: 100%;
}

.copy-url-field input {
  padding: 12px 20px;
  border-radius: 25px;
  font-size: 14px;
  border: 2px solid #eecee9;
}

.copy-url-field .main-button {
  width: 110px;
  background-color: #c71661;
  overflow: hidden;
  border-radius: 99999999px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy-url-field .main-button:hover {
  transform: scale(1.05);
  color: #ffffff;
}

.copy-url-field .main-button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.copy-url-field .main-button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%);
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

.terms-service {
  font-family: "EB Garamond", serif;
  font-weight: 600;
}

.terms-service p,
.terms-service li {
  color: var(--text-color-secondary);
}

.terms-service li {
  margin: 5px 0px;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.dark-theme-btn{
  position: fixed;
  bottom: 3vh;
  right: 20px;
  z-index: 999999;
}


/* ---------------------------------------------------------------- Toggle switch css --------------------------------------------------- */

.toggle {
  background-color: #d32499;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  box-shadow: 0 0 50px 20px rgba(0, 0, 0, 0.1);
  line-height: 1;
}

.input {
  display: none;
}

.icon {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  transition: transform 500ms;
}

.icon--moon {
  transition-delay: 200ms;
  color: rgb(255, 255, 255);
}

.icon--sun {
  transform: scale(0);
  color: yellow;
}

.icon svg {
  width: 24px;
}

#switch:checked+.icon--moon {
  transform: rotate(360deg) scale(0);
}

#switch:checked~.icon--sun {
  transition-delay: 200ms;
  transform: scale(1) rotate(360deg);
}



.react-loading-skeleton {
  height: 100% !important;
}

.btn-skelton{
  width: 100px !important;
  height: 40px !important;
  border-radius: 50px !important;
  margin-top: 30px !important;
}

.img-skeleton{
  height: 300px !important;
  opacity: 0.3 !important;
}


/* ---------------------------------------------------------------- Media query --------------------------------------------------- */



@media only screen and (max-width: 1920px) {}

@media only screen and (max-width: 1680px) {}

@media only screen and (max-width: 1540px) {}

@media only screen and (max-width: 1440px) {
  .container {
    max-width: 1140px;
    width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1366px) {}

@media only screen and (max-width: 1230px) {}

@media only screen and (max-width: 1152px) {}

@media only screen and (max-width: 1080px) {}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 991px) {
  h6 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 850px) {}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 680px) {}

@media only screen and (max-width: 576px) {}

@media only screen and (max-width: 380px) {}