.prayer-points,
.testimonies,
.counselling {
  /* padding: 150px 0px; */
  position: relative;
  background: var(--background-color-primary);
  padding-bottom: 100px;

}

.prayer-points .welcome-area,
.testimonies .welcome-area,
.counselling .welcome-area {
  min-height: 750px !important;
}


.prayer-points .container,
.testimonies .container,
.counselling .container {
  position: relative;
  padding: 50px;
  margin-top: -600px;
  z-index: 1;
  border-radius: 30px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  background-color: var(--background-color-secondary);
}

.prayer-points-title {
  text-align: center;
}

.prayer-points-title h2 {
  color: #c71661;
  font-size: 50px;
  font-weight: bold;
  font-family: Segoe Script;
  text-shadow: -4px 4px 4px var(--text-shadow);
  margin-bottom: 25px;
}

.prayer-points-title h6 {
  color: var(--text-color-secondary);
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 1px;
  max-width: 700px;
  margin: 0 auto;
}

.prayer-points-form-title h6 {
  color: var(--text-color-secondary);
}

.prayer-points-form-content {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: nowrap;
  margin-top: 50px;
}

.prayer-points-form-img,
.testimonies-form-img,
.counselling-form-img {
  width: 320px;
  background-color: #fff;
  border-radius: 200px;
  border: 10px solid #c71661;
  height: 320px;
  /* box-shadow: -8px 5px 4px rgb(197, 22, 95); */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;
}

.testimonies-form-img.detox {
  border: 10px solid #2e8b57;
}

.prayer-points-form-img img,
.testimonies-form-img img,
.counselling-form-img img {
  color: transparent;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.prayer-points-form {
  width: 100%;
}

.prayer-points-form-field {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.prayer-points-form-field input,
.prayer-points-form-field textarea {
  border: 2px solid var(--input-border-color);
  background-color: var(--input-background-color);
  color: var(--text-color-primary) !important;
}

.prayer-points-form-field input:focus,
.prayer-points-form-field textarea:focus {
  border: 2px solid #eecee9;
  box-shadow: 0 0 0 0.25rem #eecee9;
  background-color: var(--input-background-color);
}

.prayer-points-form-field input::placeholder, .prayer-points-form-field textarea::placeholder{
  color: var(--text-color-primary);
}

.name-input,
.email-input,
.number-input {
  width: 100%;
}

.checkbox-field {
  width: 100%;
  display: flex;
  padding-left: 10px;
}

.checkbox-wrapper-33 {
  --s-xsmall: 0.625em;
  --s-small: 1.2em;
  --border-width: 1px;
  --c-primary: rgb(196, 23, 95);
  --c-primary-20-percent-opacity: rgb(196 23 95 / 20%);
  --c-primary-10-percent-opacity: rgb(196 23 95 / 10%);
  --t-base: 0.4s;
  --t-fast: 0.2s;
  --e-in: ease-in;
  --e-out: cubic-bezier(.11, .29, .18, .98);
}

.checkbox-wrapper-33 .visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.checkbox-wrapper-33 .checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.checkbox-wrapper-33 .checkbox+.checkbox {
  margin-top: var(--s-small);
}

.checkbox-wrapper-33 .checkbox__symbol {
  display: inline-block;
  display: flex;
  margin-right: calc(var(--s-small) * 0.7);
  border: var(--border-width) solid var(--c-primary);
  position: relative;
  border-radius: 0.1em;
  width: 1.5em;
  height: 1.5em;
  transition: box-shadow var(--t-base) var(--e-out), background-color var(--t-base);
  box-shadow: 0 0 0 0 var(--c-primary-10-percent-opacity);
}

.checkbox-wrapper-33 .checkbox__symbol:after {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 0.25em;
  height: 0.25em;
  background-color: var(--c-primary-20-percent-opacity);
  opacity: 0;
  border-radius: 3em;
  transform: scale(1);
  transform-origin: 50% 50%;
}

.checkbox-wrapper-33 .checkbox .icon-checkbox {
  width: 1em;
  height: 1em;
  margin: auto;
  fill: none;
  stroke-width: 3;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  color: var(--c-primary);
  display: inline-block;
}

.checkbox-wrapper-33 .checkbox .icon-checkbox path {
  transition: stroke-dashoffset var(--t-fast) var(--e-in);
  stroke-dasharray: 30px, 31px;
  stroke-dashoffset: 31px;
}

.checkbox-wrapper-33 .checkbox__textwrapper {
  margin: 0;
}

.checkbox-wrapper-33 .checkbox__trigger:checked+.checkbox__symbol:after {
  -webkit-animation: ripple-33 1.5s var(--e-out);
  animation: ripple-33 1.5s var(--e-out);
}

.checkbox-wrapper-33 .checkbox__trigger:checked+.checkbox__symbol .icon-checkbox path {
  transition: stroke-dashoffset var(--t-base) var(--e-out);
  stroke-dashoffset: 0px;
}

.checkbox-wrapper-33 .checkbox__trigger:focus+.checkbox__symbol {
  box-shadow: 0 0 0 0.25em var(--c-primary-20-percent-opacity);
}

@-webkit-keyframes ripple-33 {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale(20);
  }
}

@keyframes ripple-33 {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale(20);
  }
}

.prayer-points-form-field input {
  padding: 12px 20px;
  border-radius: 25px;
  font-size: 14px;
}

.prayer-points-form-field textarea {
  border-radius: 25px;
  padding: 20px;
  font-size: 14px;
}

.prayer-points-form-btn {
  width: 300px;
  margin: 0 auto;
}

.prayer-points-form-btn button {
  padding: 1.3em 3em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #fff;
  background-color: #c71661;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin-top: 20px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.prayer-points-form-btn button.bg-green {
  background-color: #2e8b57;
}

.prayer-points-form-btn button:hover {
  background-color: #c71661;
  box-shadow: 0px 15px 20px #c7166067;
  color: #fff;
  transform: translateY(-7px);
}

.prayer-points-form-btn button.bg-green:hover {
  background-color: #2e8b57;
  box-shadow: 0px 15px 20px #2e8b565b;
  color: #fff;
  transform: translateY(-7px);
}

.prayer-points-form-btn button:active {
  transform: translateY(-1px);
}

.prayer-points-form-btn button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.prayer-points-form-btn button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%);
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

.counselling-form-text {
  width: 100%;
}

.counselling-form-text h6 {
  font-weight: 400;
  line-height: 28px;
}

.counselling-form-text h6 strong {
  color: #c71661;
}

.form-field {
  width: 32%;
  margin-bottom: 10px;
}

.form-field-area {
  width: 100%;
}

.form-field .error {
  color: red;
  margin-left: 20px;
}

/* ******************************************************* MEDIA-QUERY ********************************************************* */

@media only screen and (max-width: 991px) {
  .prayer-points-form-content {
    flex-wrap: wrap;
  }

  .prayer-points-form-img,
  .testimonies-form-img,
  .counselling-form-img {
    width: 250px;
    height: 250px;
  }

  .prayer-points .container,
  .testimonies .container,
  .counselling .container {
    position: relative;
    padding: 30px;
    margin-top: -600px;
    z-index: 1;
    border-radius: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
}

@media only screen and (max-width: 720px) {

  .form-field {
    width: 100%;
  }
}


@media only screen and (max-width: 680px) {
  .prayer-points-title h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 380px) {

  .prayer-points-form-img,
  .testimonies-form-img,
  .counselling-form-img {
    width: 200px;
    height: 200px;
  }
}