.ambasador .herosection {
    position: relative;
    /* margin-bottom: 80px;
    min-height: 700px; */
}

.ambasador .welcome-area {
    /* position: relative; */
    margin-bottom: 80px;
    min-height: 700px;
}


.ambasador .welcome-area-text h6 {
    font-weight: 400;
    color: #fff;
    line-height: 28px;
    letter-spacing: 0.25px;
}

.discover-more-btn button {
    font-size: 13px;
    border-radius: 50px;
    padding: 8px 20px;
    background-color: #C71661;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.25px;
    transition: all 0.3s ease 0s;
    margin-top: 35px;
}

.discover-more-btn button:hover {
    background-color: #050002;
    color: #eee;
}

.welcome-area-cards {
    max-width: 1140px;
    width: 90%;
    display: flex;
    justify-content: center;
    gap: 30px;
    margin: 0 auto;
    margin-top: -250px;

}

.welcome-area-card {
    padding: 30px;
    width: 30%;
    border-radius: 20px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.10);
}

.welcome-area-card:hover {
    cursor: pointer;

}

.welcome-area-card:hover .welcome-area-card-icon {
    background-color: #C71661;
}

.welcome-area-card-icon {
    background-color: #8261ee;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;

}

.welcome-area-card-title h5 {
    margin-top: 25px;
}

.welcome-area-card-subtitle h6 {
    font-weight: 400;
    font-size: 13px;
    color: var(--text-color-secondary);
    letter-spacing: 0.5px;
    line-height: 25px;
    margin-top: 20px;
}


/* ******************************************************* ABOUT PROJECT ********************************************************* */

.about-project {
    padding: 50px 0px;
}

.discuss-project,
.grow-business {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.discuss-project-img,
.grow-business-img {
    width: 30%;
    padding: 20px;
}

.discuss-project-img img,
.grow-business-img img {
    width: 100%;
    object-fit: contain;
}

.discuss-project-text,
.grow-business-text {
    width: 50%;
}

.discuss-project-text h6,
.grow-business-text h6 {
    font-weight: 400;
    color: var(--text-color-secondary);
    line-height: 28px;
    letter-spacing: 1px;
    margin-top: 30px;
}

/* ******************************************************* WORK PROCESS ********************************************************* */

.work-process {
    min-height: 200px;
    background: url('../../Images/work-process-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 50px 0px;
}

.work-process-content {
    padding: 100px 0px;
    text-align: center;
}

.work-process-title h3 {
    color: white;
    text-transform: capitalize;
    letter-spacing: 0.5px;
}

.work-process-subtitle {
    margin: 0 auto;
    margin-top: 30px;
    width: 45%;
}

.work-process-subtitle h6 {
    color: white;
    line-height: 28px;
}

.work-process-cards {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.work-process-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease 0s;
    margin-bottom: 20px;
    width: 180px;

}

.work-process-card::before {
    content: '';
    position: absolute;
    z-index: -1;
    background: #fff;
    width: 100%;
    opacity: .15;
    height: 100%;
    bottom: -10px;
    right: -10px;
    border-radius: 20px;
}

.work-process-card:hover {
    transform: translateY(-20px);
    cursor: pointer;
}

.work-process-card-text h5 {
    margin-top: 20px;
    text-transform: capitalize;
    font-family: 400;
    color: #1e1e1e;
    letter-spacing: 0.25px;
}

.work-process-card-text h6 {
    margin-top: 20px;
    text-transform: capitalize;
    font-weight: 400;
    color: var(--text-color-secondary);
    line-height: 25px;
    letter-spacing: .75px;
}

/* ******************************************************* WHAT-DO-THEY-SAY ********************************************************* */

.what-do-they-say {
    padding: 50px 0px 100px;
}

.what-do-they-say-content {
    text-align: center;
}

.what-do-they-say-subtitle {
    width: 45%;
    margin: 0 auto;
    margin-top: 30px;
}

.what-do-they-say-subtitle h6 {
    text-transform: capitalize;
    font-weight: 400;
    color: var(--text-color-secondary);
    line-height: 25px;
    letter-spacing: .75px;
}

.what-do-they-say-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 50px;
}

.what-do-they-say-card {
    background-color: #fff;
    border-radius: 20px;
    width: 30%;
    padding: 30px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.10);
}

.what-do-they-say-card-text h6 {
    text-align: left;
    font-weight: 400;
    color: var(--text-color-secondary);
    line-height: 25px;
    letter-spacing: .75px;
}

.what-do-they-say-user-panel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    text-align: left;
    transition: all 0.3s ease-in-out;

}

.what-do-they-say-user-panel-img {
    width: 65px;
    height: 65px;
    overflow: hidden;
    border-radius: 50px;
}

.what-do-they-say-user-panel-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.what-do-they-say-card-text {
    transition: all 0.3s ease-in-out;

}

.what-do-they-say-user-panel-text h5 {
    font-weight: 400;
    color: #1e1e1e;
    letter-spacing: 0.25px;
}

.what-do-they-say-user-panel-text h6 {
    font-weight: 400;
    color: #C71661;
    letter-spacing: 0.25px;
    margin-top: 10px;
}

.what-do-they-say-user-panel:hover {
    gap: 30px;
}


/* ******************************************************* PRICNG-PLAN ********************************************************* */

.pricing-plan {
    background-color: #f2f2fe;
}

.pricing-plan-content {
    padding: 100px 0px;
}

/* REST CSS IS AS SAME AS WEEKLY BUZZ PRICING SECTION  */


/* ******************************************************* PRICNG-PLAN ********************************************************* */

.ambasador-counter .weeklybuzz-counter-bg {
    height: 320px;
}

/* REST CSS IS AS SAME AS WEEKLY BUZZ COUNTER SECTION  */


/* ******************************************************* BLOG ENTRIES ********************************************************* */

.blog-entries {
    padding: 100px 0px;
}

.blog-entries-content {
    text-align: center;
}

.blog-entries-title h3 {
    text-transform: capitalize;
    letter-spacing: 0.5px;
}

.blog-entries-subtitle {
    margin: 0 auto;
    margin-top: 30px;
    width: 45%;
}

.blog-entries-subtitle h6 {
    font-weight: 400;
    color: var(--text-color-secondary);
    line-height: 28px;
    letter-spacing: 1px;
}

.blog-entries-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 50px;
}

.blog-entries-card {
    width: 30%;
    border-radius: 10px;
    box-shadow: 4px 4px 10px gray;
    padding: 20px;
}

.blog-entries-card-img {
    width: 100%;
    height: 200px;
}

.blog-entries-card-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.blog-entries-card-text h5 {
    text-transform: capitalize;
    color: #1e1e1e;
}

.blog-entries-card-text h6 {
    font-weight: 400;
    color: var(--text-color-secondary);
    letter-spacing: 0.26px;
    line-height: 26px;
    margin-top: 10px;
    font-size: 15px;
}

.blog-entries-card-btn button {
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 13px;
    border-radius: 20px;
    padding: 12px 20px;
    background-color: #C71661;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.25px;
}

.blog-entries-card-btn button:hover {
    background-color: black;
    color: #fff;


}


/* ******************************************************* AMBASADOR TALK TO US ********************************************************* */



.ambasador-talk-to-us {
    background-color: #f2f2fe;
}

.ambasador-talk-to-us .contact-us-contactdetail h6 {
    font-weight: 400;
    color: #6F8BA4;
    letter-spacing: 0.6px;
    line-height: 26px;
}

/* REST CSS IS AS SAME AS CONTACT US SECTION  */



/* ******************************************************* MEDIA QUERY ********************************************************* */




@media only screen and (max-width:1920px) {}

@media only screen and (max-width:1680px) {}

@media only screen and (max-width:1540px) {}

@media only screen and (max-width:1440px) {}

@media only screen and (max-width:1366px) {}

@media only screen and (max-width:1230px) {

    .ambasador .welcome-area-text {
        width: 60%;
    }

}

@media only screen and (max-width:1152px) {

    .what-do-they-say-cards,
    .blog-entries-cards {
        flex-wrap: wrap;
    }

    .what-do-they-say-card {
        width: 45%;
    }

    .blog-entries-card {
        width: 40%;

    }


}

@media only screen and (max-width:1080px) {
    .ambasador .welcome-area-text {
        width: 70%;
    }
}

@media only screen and (max-width:1024px) {}

@media only screen and (max-width:991px) {



    .ambasador .welcome-area {
        position: relative;
        margin-bottom: 80px;
        min-height: 600px;
    }

    .welcome-area-cards {
        flex-wrap: wrap;
        gap: 30px;
        margin-top: 0;
    }

    .welcome-area-card {
        width: 40%;
    }

}

@media only screen and (max-width:900px) {}

@media only screen and (max-width:850px) {

    .discuss-project,
    .grow-business {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        gap: 30px;
    }

    .about-project hr {
        margin-top: 50px;
    }

    .discuss-project-text,
    .grow-business-text {
        width: 80%;
        text-align: center;
    }

    .grow-business {
        margin-top: 50px;
    }

    .what-do-they-say-card {
        width: 60%;
    }

    .blog-entries-card {
        width: 50%;
    }


}

@media only screen and (max-width:767px) {

    .welcome-area-card {
        width: 50%;
    }

    .discuss-project-img,
    .grow-business-img {
        width: 50%;
    }

    .what-do-they-say-card {
        width: 70%;
    }

    .blog-entries-card {
        width: 60%;
    }
}

@media only screen and (max-width:680px) {
    .welcome-area-card {
        width: 70%;
    }

    .discuss-project-img,
    .grow-business-img {
        width: 60%;
    }

    .what-do-they-say-card {
        width: 80%;
    }

    .blog-entries-card {
        width: 70%;
    }

}

@media only screen and (max-width:576px) {
    .welcome-area-card {
        width: 90%;
    }

    .discuss-project-img,
    .grow-business-img {
        width: 70%;
    }

    .what-do-they-say-card {
        width: 90%;
    }

    .blog-entries-card {
        width: 80%;
    }
}


@media only screen and (max-width:380px) {
    .welcome-area-card {
        width: 100%;
    }

    .discuss-project-img,
    .grow-business-img {
        width: 80%;
    }

    .what-do-they-say-card {
        width: 100%;
    }

    .what-do-they-say-user-panel {
        flex-wrap: wrap;

    }

    .blog-entries-card {
        width: 100%;
    }
}