.ambassador {
    overflow-x: hidden;
}

.ambassador-form {
    min-height: 800px;
    overflow: hidden;
    position: relative;
}

.ambassador-form-content-bg {
    position: absolute;
    background-image: url('../../Images/ambassador2.jfif');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    width: 180%;
    top: 0;
    right: -45%;
    height: 1200px;
    z-index: -1;
}

.ambassador .container {
    padding: 100px 0px;
    z-index: 2;
}

.ambassador-form-title h1 {
    font-size: 50px;
    font-weight: bold;
    font-family: Segoe Script;
    color: white;
    text-shadow: -4px 4px 4px #C71661;

}

.ambassador-form-data {
    max-width: 800px;
    width: 90%;
    margin: 0 auto;
}

.ambassador-form-fields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.ambassador-form-fields input,
.ambassador-form-fields select {
    padding: 12px 20px;
    border-radius: 25px;
    font-size: 14px;
    outline: none;
    border: none;
    margin-top: 10px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.birthdate,
.gender,
.country-input,
.city-input {
    width: 48%;
}

.birthdate input,
.gender select {
    width: 100%;
}

.ambassador-form-btn button {
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 13px;
    border-radius: 20px;
    padding: 12px 20px;
    background-color: #C71661;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.25px;
}

.ambassador-form-btn button:hover {
    background-color: black;
    color: white;
}


/* ******************************************************* BECOME AMBASSADOR ********************************************************* */



.become-ambassador-title h2 {
    font-weight: bold;
    font-family: Segoe Script;
    text-shadow: -4px 4px 4px black;
    font-size: 40px;
}

.become-ambassador-data-title h3 {
    color: var(--text-color-secondary)
}

.become-ambassador-data-subtitle h6 {
    color: var(--text-color-secondary);
    line-height: 28px;

}



/* ******************************************************* MEDIA QUERY ********************************************************* */


@media only screen and (max-width:680px) {

    .birthdate,
    .gender,
    .country-input,
    .city-input {
        width: 100%;
    }
}
